export const occupations = [
  { value: null, label: "직군선택" },
  { value: "개발", label: "개발" },
  { value: "경영·비즈니스", label: "경영·비즈니스" },
  { value: "마케팅·광고", label: "마케팅·광고" },
  { value: "디자인", label: "디자인" },
  { value: "미디어", label: "미디어" },
  { value: "엔지니어링·설계", label: "엔지니어링·설계" },
  { value: "법률·법집행기관", label: "법률·법집행기관" },
];

export const occupation = {
  "": [],
  개발: [
    { value: "웹 개발자", label: "웹 개발자" },
    { value: "서버 개발자", label: "서버 개발자" },
    { value: "소프트웨어 엔지니어", label: "소프트웨어 엔지니어" },
    { value: "프론트엔드 개발자", label: "프론트엔드 개발자" },
    { value: "자바 개발자", label: "자바 개발자" },
    { value: "안드로이드 개발자", label: "안드로이드 개발자" },
    { value: "C,C++ 개발자", label: "C,C++ 개발자" },
    { value: "Node.js 개발자", label: "Node.js 개발자" },
    { value: "데이터 엔지니어", label: "데이터 엔지니어" },
    { value: "iOS 개발자", label: "iOS 개발자" },
    { value: "시스템,네트워크 관리자", label: "시스템,네트워크 관리자" },
    { value: "파이썬 개발자", label: "파이썬 개발자" },
    { value: "DevOps/시스템 관리자", label: "DevOps/시스템 관리자" },
    { value: "머신러닝 엔지니어", label: "머신러닝 엔지니어" },
    { value: "개발 매니저", label: "개발 매니저" },
    { value: "데이터 사이언티스트", label: "데이터 사이언티스트" },
    { value: "기술지원", label: "기술지원" },
    { value: "빅데이터 엔지니어", label: "빅데이터 엔지니어" },
    { value: "QA,테스트 엔지니어", label: "QA,테스트 엔지니어" },
    { value: "보안 엔지니어", label: "보안 엔지니어" },
    { value: "프로덕트 매니저", label: "프로덕트 매니저" },
    { value: "블록체인 플랫폼 엔지니어", label: "블록체인 플랫폼 엔지니어" },
    { value: "임베디드 개발자", label: "임베디드 개발자" },
    { value: "PHP 개발자", label: "PHP 개발자" },
    { value: "하드웨어 엔지니어", label: "하드웨어 엔지니어" },
    { value: "웹 퍼블리셔", label: "웹 퍼블리셔" },
    { value: ".NET 개발자", label: ".NET 개발자" },
    { value: "DBA", label: "DBA" },
    { value: "크로스플랫폼 앱 개발자", label: "크로스플랫폼 앱 개발자" },
    { value: "영상,음성 엔지니어", label: "영상,음성 엔지니어" },
    { value: "그래픽스 매니저", label: "그래픽스 매니저" },
    {
      value: "CTO,Chief Technology Officer",
      label: "CTO,Chief Technology Officer",
    },
    { value: "ERP전문가", label: "ERP전문가" },
    { value: "BI 엔지니어", label: "BI 엔지니어" },
    { value: "루비온레일즈 개발자", label: "루비온레일즈 개발자" },
    { value: "VR 엔지니어", label: "VR 엔지니어" },
    {
      value: "CIO,Chief Information Officer",
      label: "CIO,Chief Information Officer",
    },
  ],
  경영·비즈니스: [
    { value: "서비스 기획자", label: "서비스 기획자" },
    { value: "PM·PO", label: "PM·PO" },
    { value: "사업개발·기획자", label: "사업개발·기획자" },
    { value: "전략 기획자", label: "전략 기획자" },
    { value: "경영지원", label: "경영지원" },
    { value: "운영 매니저", label: "운영 매니저" },
    { value: "회계·경리", label: "회계·경리" },
    { value: "데이터 분석가", label: "데이터 분석가" },
    { value: "자금담당", label: "자금담당" },
    { value: "해외 사업개발·기획자", label: "해외 사업개발·기획자" },
    { value: "총무", label: "총무" },
    { value: "상품기획자(BM)", label: "상품기획자(BM)" },
    { value: "컨설턴트", label: "컨설턴트" },
    { value: "조직관리", label: "조직관리" },
    { value: "오피스관리", label: "오피스관리" },
    { value: "사무보조", label: "사무보조" },
    { value: "리스크 관리 전문가", label: "리스크 관리 전문가" },
    { value: "구매담당", label: "구매담당" },
    { value: "정보보호 담당자", label: "정보보호 담당자" },
    { value: "경영 혁신가", label: "경영 혁신가" },
    { value: "비서", label: "비서" },
    { value: "ISMS(정보보호체계)", label: "ISMS(정보보호체계)" },
    { value: "지역 관리 매니저", label: "지역 관리 매니저" },
    {
      value: "CFO,Chief Finanical Officer",
      label: "CFO,Chief Finanical Officer",
    },
    {
      value: "CSO,Chief Strategy Officer",
      label: "CSO,Chief Strategy Officer",
    },
    { value: "안내원", label: "안내원" },
    { value: "애자일 코치", label: "애자일 코치" },
    { value: "공연 기획자", label: "공연 기획자" },
    {
      value: "CEO,Chief Executive Officer",
      label: "CEO,Chief Executive Officer",
    },
    { value: "전시 기획자", label: "전시 기획자" },
    { value: "세미나/포럼 기획자", label: "세미나/포럼 기획자" },
    { value: "지점장", label: "지점장" },
    { value: "사내 심리상담가", label: "사내 심리상담가" },
  ],
  마케팅·광고: [
    { value: "디지털 마케터", label: "디지털 마케터" },
    { value: "퍼포먼스 마케터", label: "퍼포먼스 마케터" },
    { value: "콘텐츠 마케터", label: "콘텐츠 마케터" },
    { value: "마케팅 전략 기획자", label: "마케팅 전략 기획자" },
    { value: "브랜드 마케터", label: "브랜드 마케터" },
    { value: "광고 기획자(AE)", label: "광고 기획자(AE)" },
    { value: "글로벌 마케팅", label: "글로벌 마케팅" },
    { value: "소셜 마케터", label: "소셜 마케터" },
    { value: "그로스 해커", label: "그로스 해커" },
    { value: "모바일마케팅", label: "모바일마케팅" },
    { value: "마케팅 디렉터", label: "마케팅 디렉터" },
    { value: "PR 전문가", label: "PR 전문가" },
    { value: "카피라이터", label: "카피라이터" },
    { value: "키워드광고", label: "키워드광고" },
    { value: "제휴", label: "제휴" },
    { value: "마켓 리서처", label: "마켓 리서처" },
    { value: "ATL 마케터", label: "ATL 마케터" },
    { value: "BTL 마케터", label: "BTL 마케터" },
    {
      value: "CMO,Chief Marketing Officer",
      label: "CMO,Chief Marketing Officer",
    },
    { value: "Sports 전문가", label: "Sports 전문가" },
    { value: "CBO,Chief Brand Officer", label: "CBO,Chief Brand Officer" },
  ],
  디자인: [
    { value: "UX 디자이너", label: "UX 디자이너" },
    { value: "UI,GUI 디자이너", label: "UI,GUI 디자이너" },
    { value: "웹 디자이너", label: "웹 디자이너" },
    { value: "그래픽 디자이너", label: "그래픽 디자이너" },
    { value: "모바일 디자이너", label: "모바일 디자이너" },
    { value: "BI/BX 디자이너", label: "BI/BX 디자이너" },
    { value: "광고 디자이너", label: "광고 디자이너" },
    { value: "제품 디자이너", label: "제품 디자이너" },
    { value: "3D 디자이너", label: "3D 디자이너" },
    { value: "영상,모션 디자이너", label: "영상,모션 디자이너" },
    { value: "패키지 디자이너", label: "패키지 디자이너" },
    { value: "아트 디렉터", label: "아트 디렉터" },
    { value: "2D 디자이너", label: "2D 디자이너" },
    { value: "일러스트레이터", label: "일러스트레이터" },
    { value: "캐릭터 디자이너", label: "캐릭터 디자이너" },
    { value: "패션 디자이너", label: "패션 디자이너" },
    { value: "공간 디자이너", label: "공간 디자이너" },
    { value: "출판,편집 디자이너", label: "출판,편집 디자이너" },
    { value: "산업 디자이너", label: "산업 디자이너" },
    { value: "인테리어 디자이너", label: "인테리어 디자이너" },
    { value: "VMD", label: "VMD" },
    { value: "전시 디자이너", label: "전시 디자이너" },
    { value: "패브릭 디자이너", label: "패브릭 디자이너" },
    { value: "가구 디자이너", label: "가구 디자이너" },
    { value: "조경 디자이너", label: "조경 디자이너" },
  ],
  미디어: [
    { value: "콘텐츠 크리에이터", label: "콘텐츠 크리에이터" },
    { value: "PD", label: "PD" },
    { value: "영상 편집가", label: "영상 편집가" },
    { value: "에디터", label: "에디터" },
    { value: "비디오 제작", label: "비디오 제작" },
    { value: "작가", label: "작가" },
    { value: "연예,엔터테인먼트", label: "연예,엔터테인먼트" },
    { value: "통·번역", label: "통·번역" },
    { value: "사진작가", label: "사진작가" },
    { value: "출판 기획자", label: "출판 기획자" },
    { value: "저널리스트", label: "저널리스트" },
    { value: "라이센스 관리자", label: "라이센스 관리자" },
    { value: "큐레이터", label: "큐레이터" },
    { value: "음향 엔지니어", label: "음향 엔지니어" },
    { value: "리포터", label: "리포터" },
  ],
  엔지니어링·설계: [
    { value: "전자 엔지니어", label: "전자 엔지니어" },
    { value: "기계 엔지니어", label: "기계 엔지니어" },
    { value: "전기 엔지니어", label: "전기 엔지니어" },
    { value: "로봇·자동화", label: "로봇·자동화" },
    { value: "CAD·3D 설계자", label: "CAD·3D 설계자" },
    { value: "제어 엔지니어", label: "제어 엔지니어" },
    { value: "제품 엔지니어", label: "제품 엔지니어" },
    { value: "전기기계 공학자", label: "전기기계 공학자" },
    { value: "장비 엔지니어", label: "장비 엔지니어" },
    { value: "QA 엔지니어", label: "QA 엔지니어" },
    { value: "설비 엔지니어", label: "설비 엔지니어" },
    { value: "공정 엔지니어", label: "공정 엔지니어" },
    { value: "RF 엔지니어", label: "RF 엔지니어" },
    { value: "자동차 공학자", label: "자동차 공학자" },
    { value: "화학공학 엔지니어", label: "화학공학 엔지니어" },
    { value: "환경 엔지니어", label: "환경 엔지니어" },
    { value: "프로젝트 엔지니어", label: "프로젝트 엔지니어" },
    { value: "QC 엔지니어", label: "QC 엔지니어" },
    { value: "항공우주 공확자", label: "항공우주 공확자" },
    { value: "환경 안전기사", label: "환경 안전기사" },
    { value: "인허가 담당 엔지니어", label: "인허가 담당 엔지니어" },
    { value: "도면 담당자", label: "도면 담당자" },
    { value: "건설 엔지니어", label: "건설 엔지니어" },
    { value: "토목기사", label: "토목기사" },
    { value: "도면 작성가", label: "도면 작성가" },
    { value: "재료 공학자", label: "재료 공학자" },
    { value: "플랜트 엔지니어", label: "플랜트 엔지니어" },
    { value: "소방안전 기술자", label: "소방안전 기술자" },
    { value: "고압설계 엔지니어", label: "고압설계 엔지니어" },
    { value: "산업 엔지니어", label: "산업 엔지니어" },
    { value: "사업수주 엔지니어", label: "사업수주 엔지니어" },
    { value: "구조공학 엔지니어", label: "구조공학 엔지니어" },
    { value: "시운전 엔지니어", label: "시운전 엔지니어" },
    { value: "지리정보시스템", label: "지리정보시스템" },
    { value: "보건안전 엔지니어", label: "보건안전 엔지니어" },
    { value: "I&C 엔지니어", label: "I&C 엔지니어" },
    { value: "선박 공학자", label: "선박 공학자" },
    { value: "원자력·에너지", label: "원자력·에너지" },
    { value: "석유공학 엔지니어", label: "석유공학 엔지니어" },
    { value: "배관설계 엔지니어", label: "배관설계 엔지니어" },
    { value: "전자계전 엔지니어", label: "전자계전 엔지니어" },
    { value: "농업 공학자", label: "농업 공학자" },
    { value: "유전 공학자", label: "유전 공학자" },
    { value: "생물의학자", label: "생물의학자" },
    { value: "보이러 엔지니어", label: "보이러 엔지니어" },
    { value: "세라믹 엔지니어", label: "세라믹 엔지니어" },
    { value: "시추 엔지니어", label: "시추 엔지니어" },
    { value: "지리학자", label: "지리학자" },
    { value: "지질공학자", label: "지질공학자" },
    { value: "생산공학 엔지니어", label: "생산공학 엔지니어" },
    { value: "해양공학자", label: "해양공학자" },
    { value: "금속 공학자", label: "금속 공학자" },
    { value: "광산 기술자", label: "광산 기술자" },
    { value: "광산 안전 기술자", label: "광산 안전 기술자" },
    { value: "플라스틱 엔지니어", label: "플라스틱 엔지니어" },
    { value: "저수처리 엔지니어", label: "저수처리 엔지니어" },
    { value: "회전기계 엔지니어", label: "회전기계 엔지니어" },
    { value: "터빈공학자", label: "터빈공학자" },
  ],
  법률·법집행기관: [
    { value: "법무담당", label: "법무담당" },
    { value: "변호사", label: "변호사" },
    { value: "특허담당", label: "특허담당" },
    { value: "법무 자문위원", label: "법무 자문위원" },
    { value: "변리사", label: "변리사" },
    { value: "법무사", label: "법무사" },
    { value: "수사관", label: "수사관" },
    { value: "법무관", label: "법무관" },
    { value: "보호 관찰관", label: "보호 관찰관" },
    { value: "경찰관", label: "경찰관" },
    { value: "보안요원", label: "보안요원" },
    { value: "경호원", label: "경호원" },
  ],
};
